import styled from 'styled-components';

import { colors } from '@hultafors/solidgear/helpers';
import { fontFamilies } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';
import { fontWeights } from '@hultafors/solidgear/helpers';
import { lineHeights } from '@hultafors/solidgear/helpers';

interface StyledH3Props {
  $white?: boolean;
  $medium?: boolean;
  $contentType?: boolean;
  $center?: boolean;
}

export const StyledH3 = styled.h3<StyledH3Props>`
  font-family: ${({ $medium }) =>
    $medium ? fontFamilies.fontRegularBold : fontFamilies.fontRegular};
  font-style: normal;
  font-weight: ${({ $medium }) =>
    $medium ? fontWeights.fontMedium : 'normal'};
  font-size: ${({ $contentType }) =>
    $contentType ? fontSizes.header3Content : fontSizes.header3};
  line-height: ${({ $contentType }) =>
    $contentType ? lineHeights.header3Content : lineHeights.header3};
  text-align: ${({ $center }) => ($center ? 'center' : 'initial')};
  color: ${({ $white }) => ($white ? colors.white : colors.solidBlack)};
`;
