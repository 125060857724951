import { StyledH3 } from './H3.styled';
interface H3Props {
  center?: boolean;
  contentType?: boolean;
  medium?: boolean;
  white?: boolean;
  children?: React.ReactNode;
  className?: string;
}
export const H3: React.FC<H3Props> = ({
  children,
  center,
  contentType,
  medium,
  white,
  className,
}) => {
  return (
    <StyledH3
      className={className}
      $center={center}
      $contentType={contentType}
      $medium={medium}
      $white={white}
    >
      {children}
    </StyledH3>
  );
};
